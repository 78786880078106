<template>
  <Button
    :label="t(`holidayVacations.registerForEmployee`)"
    class="mt-3 ml-3 mr-1 md:ml-0 md:mr-0 bg-yellow"
    @click="openModal()"
  />
  <Modal @closeModal="showModal = !showModal" :visible="showModal">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-umbrella-beach"></i>
    </span>

    <p>{{ t("message") }}</p>
    <span class="flex items-center">
      <DatePicker
        class="bg-white text-green-darkest"
        :disabled="true"
        v-model="startTime"
      />
      <p class="mx-4" v-if="endTime">{{ t("to") }}</p>
      <DatePicker
        class="bg-white text-green-darkest"
        v-if="endTime"
        :disabled="true"
        v-model="endTime"
      />
    </span>
    <SelectorEmployee class="mt-3 w-1/2 md:w-1/2" v-model="selectedEmployee" />
    <p class="mx-4 mt-3" v-if="endTime">{{ t("reason") }}</p>
    <div class="flex w-full justify-evenly">
      <Checkbox
        :label="t('label1')"
        class="my-3"
        :small="true"
        v-model="vacation"
        @change="vacation"
        @click="sick = false"
      />
      <Checkbox
        :label="t('label2')"
        class="my-3"
        :small="true"
        v-model="sick"
        @change="sick"
        @click="vacation = false"
      />
    </div>
    <Input
      v-if="vacation"
      class="my-6"
      inputWidth="w-20"
      :label="t('label5')"
      type="number"
      v-model="vacationDays"
    />
    <Input
      v-if="sick"
      class="my-6"
      inputWidth="w-20"
      :label="t('label4')"
      type="number"
      v-model="vacationDays"
    />
    <Button
      class="bg-yellow hover:bg-green"
      :label="t('confirm')"
      @click="submitNewRequest()"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Input from "@/components/Input";
import DatePicker from "@/components/DatePicker";
import Checkbox from "@/components/Checkbox";
import SelectorEmployee from "@/components/SelectorEmployee";
import { errorLogging } from "@/global/services/errorLogging";
import { requests } from "@/global/services/requests";
import { systemState } from "@/global/services/systemState";
import { ref, watchEffect } from "vue";
import { employee } from "@/global/services/employee";

import { useI18n } from "vue-i18n";

export default {
  name: "AddVacationButton",
  components: {
    Button,
    Modal,
    DatePicker,
    Input,
    SelectorEmployee,
    Checkbox
  },
  props: {
    start: Date,
    end: Date
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          message: "Register a vacation/free day for",
          to: "to",
          reason: "Reason?",
          label1: "Vacation",
          label2: "Sickleave",
          label4: "Number of sick days",
          label5: "Number of vacation days",
          confirm: "Confirm"
        },
        dk: {
          message: "Registrer ferie-/fridag den",
          to: "til",
          reason: "Årsag?",
          label1: "Ferie",
          label2: "Sygedage",
          label4: "Antal sygedage",
          label5: "Antal feriedage",
          confirm: "Godkend"
        }
      }
    });

    const state = {
      showModal: ref(false),
      startTime: ref(props.start?.getTime()),
      endTime: ref(props.end?.getTime()),
      vacationDays: ref(0),
      vacation: ref(false),
      sick: ref(false),
      selectedEmployee: ref(undefined)
    };

    watchEffect(() => {
      state.startTime.value = props.start?.getTime();
      state.endTime.value = props.end?.getTime();
    });

    const functions = {
      openModal: () => {
        if (props.start !== null)
          state.showModal.value = !state.showModal.value;
        else errorLogging.setWarning("Vælg venligst en eller flere dage");
      },
      submitNewRequest: () => {
        systemState.startProcessing();
        const diffDays =
          Math.round(
            (props.end.getTime() - props.start.getTime()) / (1000 * 3600 * 24)
          ) + 1;
        console.log("Dage", diffDays, state.vacationDays.value);
        if (diffDays < state.vacationDays.value) {
          systemState.stopProcessing();
          errorLogging.setWarning(
            "Du kan angive flere ferie- / sygedage end antal dage i perioden."
          );
        } else {
          const data = {
            data: {
              sick: state.sick.value,
              vacation: state.vacation.value,
              start: props.start,
              end: props.end,
              vacationDays: state.vacationDays.value,
              eid: state.selectedEmployee.value.id
            },
            requestFor: state.selectedEmployee.value.id,
            requestBy: employee.getId(),
            requestType: "AddVacationDay"
          };
          requests
            .createVacationRequest(data)
            .then(message => {
              systemState.stopProcessing();
              state.showModal.value = false;
              errorLogging.setSuccess(message);
            })
            .catch(message => {
              errorLogging.setError(message);
              systemState.stopProcessing();
            });
        }
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
