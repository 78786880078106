import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { authentication } from "@/global/services/authentication";
import { errorLogging } from "@/global/services/errorLogging";
import { period } from "@/timeregistration/services/period";
import { ref, watchEffect } from "vue";
import { employee } from "../../global/services/employee";

let userVacationsDetacher: any = undefined;
let vacationsDetacher: any = undefined;
let vacationRequestsDetacher: any = undefined;
let byEmployeeVacationRequestsDetacher: any = undefined;
let forEmployeeVacationRequestsDetacher: any = undefined;
const vacationRequests = ref([] as Array<firebase.firestore.DocumentSnapshot>);
const vacationsList = ref([] as Array<firebase.firestore.DocumentSnapshot>);

const getRequests = ref(false);

export const vacations = {
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateEmployeeVacationRequests: function(): Promise<number | string> {
    vacationRequests.value = [];
    byEmployeeVacationRequestsDetacher !== undefined
      ? byEmployeeVacationRequestsDetacher()
      : null;
    forEmployeeVacationRequestsDetacher !== undefined
      ? forEmployeeVacationRequestsDetacher()
      : null;
    return new Promise((resolve, reject) => {
      const requstsCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .where("requestFor", "==", employee.getId())
        .where("deleted", "==", null)
        .orderBy("created", "desc");
      forEmployeeVacationRequestsDetacher = requstsCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for vacationRequestsFor: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;

              if (docChange.type === "added") {
                vacationRequests.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = vacationRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationRequests.value.splice(idx, 1, docReference);
              }

              if (docChange.type === "removed") {
                const idx = vacationRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationRequests.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
      const requstsByCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .where("requestBy", "==", employee.getId())
        .where("deleted", "==", null)
        .orderBy("created", "desc");
      byEmployeeVacationRequestsDetacher = requstsByCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for vacationRequestsBy: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;

              if (docChange.type === "added") {
                vacationRequests.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = vacationRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationRequests.value.splice(idx, 1, docReference);
              }

              if (docChange.type === "removed") {
                const idx = vacationRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationRequests.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateVacationRequests: function(): Promise<number | string> {
    vacationRequests.value = [];
    vacationRequestsDetacher !== undefined ? vacationRequestsDetacher() : null;
    return new Promise((resolve, reject) => {
      const requstsCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .where("deleted", "==", null)
        .orderBy("created", "desc");
      vacationRequestsDetacher = requstsCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for vacationRequests: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;
              if (docChange.type === "added") {
                vacationRequests.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = vacationRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationRequests.value.splice(idx, 1, docReference);
              }
              if (docChange.type === "removed") {
                const idx = vacationRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationRequests.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
    });
  },
  getVacationRequests: function(reference = false) {
    if (vacationRequests.value.length === 0) {
      getRequests.value = true;
    }
    if (reference) {
      return vacationRequests;
    } else return vacationRequests.value;
  },

  /**
   * Deletes a request
   * @param requestDocumentId Vue array refference value to populate with documents containing requests
   * @returns "Anmodning er slettet" if success or error message on error
   */
  deleteRequest: function(requestDocumentId: string) {
    return new Promise((resolve, reject) => {
      const requestReference = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests");
      const docRef = requestReference.doc(requestDocumentId);

      docRef
        .get()
        .then((docSnap: any) => {
          return docRef
            .update({
              deleted: {
                by: authentication.getUserUid(),
                time: firebase.firestore.FieldValue.serverTimestamp()
              }
            })
            .then(() => {
              return docRef
                .get()
                .then((docSnap: any) => {
                  resolve("Anmodning er slettet");
                })
                .catch((error: any) => reject(error.message));
            })
            .catch((error: any) => reject(error.message));
        })
        .catch((error: any) => reject(error.message));
    });
  },

  /**
   * Accept a request
   * @param requestDocumentId Id of the request that should be accepted
   * @returns "Anmodning er godkendt" if success or error message on error
   */
  acceptAdd: function(requestDocument: firebase.firestore.DocumentSnapshot) {
    const dataCopy = Object.assign({}, requestDocument.data()?.data);
    dataCopy.created = {
      by: employee.getId(),
      time: firebase.firestore.FieldValue.serverTimestamp()
    };
    dataCopy.deleted = null;
    // Burde struktureres så dokumentet opdateres eller oprettes først for anmodning godkendes
    return new Promise((resolve, reject) => {
      requestDocument.ref
        .update({
          accepted: {
            by: authentication.getUserUid(),
            time: firebase.firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => {
          firebase
            .firestore()
            .collection("farms/" + authentication.getFarmId() + "/vacations")
            .add(dataCopy);
          resolve("Anmodning godkendt");
        })
        .catch(error => errorLogging.setError(error));
    });
  },

  /**
   * Accept a request
   * @param requestDocumentId Id of the request that should be accepted
   * @returns "Anmodning er godkendt" if success or error message on error
   */
  acceptRequest: function(
    requestDocument: firebase.firestore.DocumentSnapshot,
    vacationDays: number
  ) {
    const dataCopy = Object.assign({}, requestDocument.data()?.data);
    dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();
    dataCopy.vacationDays = vacationDays;
    // Burde struktureres så dokumentet opdateres eller oprettes først for anmodning godkendes
    console.log(dataCopy);
    return new Promise((resolve, reject) => {
      requestDocument.ref
        .update({
          accepted: {
            by: authentication.getUserUid(),
            time: firebase.firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => {
          firebase
            .firestore()
            .collection("farms/" + authentication.getFarmId() + "/vacations")
            .add(dataCopy);
          resolve("Anmodning godkendt");
        })
        .catch(e => {
          reject(e.message);
        });
    });
  },

  /**
   * Decline a request
   * @param requestDocumentId Id of the request that should be accepted
   * @returns "Anmodning er godkendt" if success or error message on error
   */
  declineRequest: function(
    requestDocument: firebase.firestore.DocumentSnapshot
  ) {
    return new Promise((resolve, reject) => {
      requestDocument.ref
        .update({
          declined: {
            by: authentication.getUserUid(),
            time: firebase.firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => resolve("Anmodning afvist"))
        .catch(error => errorLogging.setError(error));
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  addVacation: function(data: any): Promise<void | string> {
    return new Promise((resolve, reject) => {
      const dataCopy = Object.assign({}, data);
      dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();
      dataCopy.deleted = null;
      firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/vacations")
        .add(dataCopy)
        .then(() => resolve())
        .catch(e => reject(e.message));
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateUserVacations: function(): Promise<number | string> {
    userVacationsDetacher !== undefined ? userVacationsDetacher() : null;
    return new Promise((resolve, reject) => {
      const vacationsCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/vacations")
        .where("eid", "==", employee.getId())
        .orderBy("created", "desc");
      userVacationsDetacher = vacationsCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for vacations: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;

              if (docChange.type === "added") {
                vacationsList.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = vacationsList.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationsList.value.splice(idx, 1, docReference);
              }

              if (docChange.type === "removed") {
                const idx = vacationsList.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationsList.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateVacations: function(): Promise<number | string> {
    vacationsDetacher !== undefined ? vacationsDetacher() : null;
    return new Promise((resolve, reject) => {
      const vacationsCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/vacations")
        .orderBy("created", "desc");
      vacationsDetacher = vacationsCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for vacations: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;

              if (docChange.type === "added") {
                vacationsList.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = vacationsList.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationsList.value.splice(idx, 1, docReference);
              }

              if (docChange.type === "removed") {
                const idx = vacationsList.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                vacationsList.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
    });
  },

  getVacations: function(reference = false) {
    if (vacationsList.value.length === 0) {
      authentication.isEmployee() ? null : vacations.updateVacations();
    }
    if (reference) {
      return vacationsList;
    } else return vacationsList.value;
  }
};

watchEffect(() => {
  if (getRequests.value && employee.get()) {
    authentication.isEmployee()
      ? vacations.updateEmployeeVacationRequests()
      : vacations.updateVacationRequests();
  }
});
