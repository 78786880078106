<template>
  <Header :title="t(`holidayVacations.title`)" icon="fa-umbrella-beach" />
  <Content
    class=" pt-3 flex-wrap md:flex-nowrap items-start overflow-y-scroll md:overscroll-y-none"
  >
    <div class="flex flex-col w-full md:w-1/2 md:h-full">
      <div class="calendar w-full md:h-1/2">
        <div
          class="bg-green-darkest flex justify-evenly rounded-t-3xl py-2 mx-3"
        >
          <div class=" w-36 text-green flex items-center justify-between">
            <span
              @click="decreaseMonth()"
              class="flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
            >
              <i class="fas fa-chevron-left"></i>
            </span>
            <p class="font-bold">{{ t(`time.months[${month}]`) }}</p>
            <span
              @click="increaseMonth()"
              class="flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
            >
              <i class="fas fa-chevron-right"></i>
            </span>
          </div>

          <div class="w-36 text-green flex items-center justify-between">
            <span
              @click="decreaseYear()"
              class="flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
            >
              <i class="fas fa-chevron-left"></i>
            </span>
            <p class="font-bold">{{ year }}</p>
            <span
              @click="increaseYear()"
              class="flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
            >
              <i class="fas fa-chevron-right"></i>
            </span>
          </div>
        </div>

        <div class="calendar__body mx-3">
          <div class="calendar__days text-green bg-green-darkest">
            <div>M</div>
            <div>T</div>
            <div>O</div>
            <div>T</div>
            <div>F</div>
            <div>L</div>
            <div>S</div>
          </div>

          <div class="calendar__dates bg-white">
            <div
              @click="selectDate(date)"
              v-for="date in dates"
              :key="date"
              :class="[
                isStart(date)
                  ? 'calendar__date--first-date calendar__date--range-start'
                  : '',
                isSelected(date) ? 'calendar__date--selected' : '',
                isEnd(date)
                  ? 'calendar__date--last-date calendar__date--range-end'
                  : ''
              ]"
              class="calendar__date"
            >
              <span>{{ date.getDate() }}</span>
            </div>
          </div>
        </div>

        <div
          class="bg-green-darkest flex justify-evenly items-center rounded-b-3xl pb-3 mx-3"
        >
          <RequestVacationButton
            v-if="isEmployee()"
            :start="startVacation"
            :end="endVacation"
            :vacationDaysLeft="25 - vacationDaysUsed"
          />
          <AddVacationButton
            v-if="!isEmployee()"
            :start="startVacation"
            :end="endVacation"
          />
          <TakeVacationButton
            v-if="!isEmployee()"
            :start="startVacation"
            :end="endVacation"
          />
        </div>
      </div>
      <div class="w-full md:h-1/2 mt-3 md:mt-0">
        <div class="bg-green-darkest rounded-3xl pb-6 mx-3 px-3 h-full">
          <div class="flex justify-between">
            <div
              class="rounded-tl-3xl px-6 pb-2 border-green-dark border-b-2 border-solid text-center flex-grow "
            >
              <p class="text-green pt-2 tracking-wider">
                {{ t(`holidayVacations.requestsForHoliday`) }}
              </p>
            </div>
          </div>
          <div class="h-5/6 overflow-y-scroll">
            <template v-for="request in vacationRequests" :key="request.id">
              <RequestVacationItem
                v-if="
                  request.data().requestType === 'RequestVacationDay' &&
                    ((request.data().accepted && showAccepted) ||
                      (!request.data().accepted && !request.data().declined) ||
                      (request.data().declined && showDeclined))
                "
                :request="request"
              />
              <AddVacationItem
                v-if="
                  request.data().requestType === 'AddVacationDay' &&
                    ((request.data().accepted && showAccepted) ||
                      (!request.data().accepted && !request.data().declined) ||
                      (request.data().declined && showDeclined))
                "
                :request="request"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full md:w-1/2 md:h-full mt-3 md:mt-0">
      <div class="w-full mb-3 mr-3">
        <div class="relative bg-green-darkest rounded-3xl ml-3 h-96 md:h-full">
          <div class="flex flex-col items-center justify-center">
            <div
              class="rounded-tl-3xl w-full border-green-dark border-b-2 border-solid text-center flex-grow "
            >
              <p class="text-green pt-2 tracking-wider">
                {{ t(`holidayVacations.registeredForHoliday`) }}
              </p>
            </div>
            <SelectorEmployee
              v-if="!isEmployee()"
              class="w-3/4 md:w-1/2 mt-2"
              v-model="selectedEmployee"
              all="true"
            />
          </div>
          <div
            v-if="selectedEmployee !== undefined"
            class=" h-60 md:h-5/6 overflow-y-scroll"
          >
            <template v-for="vacation in vacationsList" :key="vacation.id">
              <div
                v-if="
                  vacation.data().eid === selectedEmployee.id ||
                    selectedEmployee === '*'
                "
                class="relative ml-4 mr-8 my-3 shadow-md bg-white border-green border-l-8 text-green-dark p-3 flex flex-col justify-evenly items-center"
              >
                <p class="text-gray text-sm absolute left-2 top-1">
                  {{
                    vacation.data().vacationDays > 0 && !vacation.data().sick
                      ? t("holiday")
                      : ""
                  }}
                  {{
                    vacation.data().vacationDays === 0 && !vacation.data().sick
                      ? t("freeDay")
                      : ""
                  }}
                  {{ vacation.data().sick ? t("sick") : "" }}
                </p>
                <p
                  v-show="vacation.data().vacation || vacation.data().sick"
                  class="text-gray text-sm absolute right-0 top-1"
                >
                  {{
                    vacation.data().vacation ? t("holidaysUsed") : t("sickDays")
                  }}
                  <span
                    class="ml-3 -mt-4 -mr-4 w-8 h-8 rounded-full bg-green text-white text-lg inline-flex items-center justify-center"
                    >{{ vacation.data().vacationDays }}</span
                  >
                </p>
                <span
                  class="mt-6 flex items-center justify-center"
                  v-if="formatStatus(vacation.data()) === 0"
                  ><span
                    class="block w-4 h-4 mr-3 bg-yellow rounded-full"
                  ></span>
                  <p class="text-sm">{{ t("upcomming") }}</p></span
                >
                <span
                  class="mt-6 flex items-center justify-center"
                  v-if="formatStatus(vacation.data()) === 1"
                >
                  <span
                    class="block w-4 h-4 mr-3 bg-red-500 rounded-full"
                  ></span>
                  <p class="text-sm">{{ t("current") }}</p></span
                >
                <span
                  class="mt-6 flex items-center justify-center"
                  v-if="formatStatus(vacation.data()) === 2"
                >
                  <span
                    class="block w-4 h-4 mr-3 bg-green-light rounded-full"
                  ></span>
                  <p class="text-sm">{{ t("ended") }}</p></span
                >
                <p class="font-bold">
                  {{ formatIdToName(vacation.data().eid) }}
                </p>
                <p class=" text-green ">
                  {{ formatDate(vacation.data().start.toDate()) }}
                  <span v-if="vacation.data().end" class="text-gray mx-3">{{
                    t("to")
                  }}</span>
                  <span v-if="vacation.data().end">{{
                    formatDate(vacation.data().end.toDate())
                  }}</span>
                </p>
              </div>
            </template>
          </div>
          <div
            v-if="selectedEmployee !== '*' && selectedEmployee !== undefined"
            class="absolute bottom-0 flex justify-between w-full"
          >
            <div class="rounded-bl-3xl pb-1 bg-red-500 text-center flex-grow ">
              <p class="leading-6 font-bold text-lg">
                {{ vacationDaysUsed }}
              </p>
              <p class="leading-3 text-xs tracking-wider">
                {{ t("holidaysUsed") }}
              </p>
            </div>
            <div
              class="rounded-br-3xl pb-1 bg-green-light text-center flex-grow"
            >
              <p class="leading-6 font-bold text-lg">
                {{ 25 - vacationDaysUsed }}
              </p>
              <p class="leading-3 text-xs tracking-wider">
                {{ t("holidaysRemaining") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script>
import Header from "@/components/Header";
import Content from "@/components/Content";
import RequestVacationButton from "@/components/TimeRegistration/Vacations/RequestVacationButton";
import AddVacationButton from "@/components/TimeRegistration/Vacations/AddVacationButton";
import TakeVacationButton from "@/components/TimeRegistration/Vacations/TakeVacationButton";
import SelectorEmployee from "@/components/SelectorEmployee";
import RequestVacationItem from "@/components/TimeRegistration/Requests/RequestVacationItem";
import AddVacationItem from "@/components/TimeRegistration/Requests/AddVacationItem";
import { ref, watchEffect } from "vue";
import { authentication } from "@/global/services/authentication";
import { vacations } from "@/timeregistration/services/vacations";
import { employee } from "@/global/services/employee";

import { useI18n } from "vue-i18n";

export default {
  name: "Vacations",
  components: {
    Header,
    Content,
    RequestVacationButton,
    AddVacationButton,
    TakeVacationButton,
    SelectorEmployee,
    RequestVacationItem,
    AddVacationItem
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          holiday: "Holiday",
          freeDay: "Free day",
          sick: "Sickleave",
          sickDays: "Number of sick days",
          upcomming: "Upcomming",
          current: "Currently being held",
          ended: "Is over",
          to: "to",
          holidaysUsed: "Holidays spent",
          holidaysRemaining: "Holidays remaining"
        },
        dk: {
          holiday: "Ferie",
          freeDay: "Fri",
          sick: "Sygdom",
          sickDays: "Antal sygedage",
          upcomming: "Kommende",
          current: "Igangværende",
          ended: "Afholdt",
          to: "til",
          holidaysUsed: "Forbrugte feriedage",
          holidaysRemaining: "Resterende feriedage"
        }
      }
    });
    const today = new Date();
    today.setDate(today.getDate() - 1);

    const state = {
      month: ref(today.getMonth()),
      year: ref(today.getFullYear()),
      dates: ref([]),
      selectedEmployee: ref(undefined),

      vacationRequests: vacations.getVacationRequests(true),
      vacationsList: vacations.getVacations(true),
      startVacation: ref(null),
      endVacation: ref(null),
      vacationDaysUsed: ref(0)
    };

    watchEffect(() => {
      if (state.selectedEmployee.value) {
        state.vacationDaysUsed.value = state.vacationsList.value
          .filter(v => v.data().eid === state.selectedEmployee.value.id)
          .reduce((a, c) => a + parseInt(c.data().vacationDays), 0);
      }
    });

    watchEffect(() => {
      if (authentication.isEmployee() && employee.get()) {
        console.log("Hello!", employee.getId());
        vacations.updateUserVacations();
        state.selectedEmployee.value = employee.get();
      }
    });

    function getDates() {
      state.dates.value = [];
      const selectedDate = new Date(state.year.value, state.month.value);
      const startDate = new Date(state.year.value, state.month.value);
      selectedDate.getDay() === 0
        ? startDate.setDate(selectedDate.getDate() - 6)
        : startDate.setDate(
            selectedDate.getDate() - (selectedDate.getDay() - 1)
          );
      const endDate = new Date(state.year.value, state.month.value);
      endDate.setMonth(endDate.getMonth() + 1);
      console.log(startDate, selectedDate, endDate);
      for (let i = 0; i < 42; i++) {
        if (startDate.getMonth() < today.getMonth()) {
          state.dates.value.push(new Date(startDate));
        } else if (
          startDate.getMonth() === today.getMonth() &&
          startDate.getDate() <= today.getDate()
        ) {
          state.dates.value.push(new Date(startDate));
        } else {
          state.dates.value.push(new Date(startDate));
        }

        startDate.setDate(startDate.getDate() + 1);
      }
    }

    getDates();

    const functions = {
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,
      formatIdToName: employee.formatIdToName,

      formatStatus: function(vacationData) {
        let res = 0;
        if (
          vacationData.start.toDate() < today &&
          vacationData.end.toDate() > today
        ) {
          res = 1;
        } else if (vacationData.end.toDate() < today) {
          res = 2;
        }
        return res;
      },

      formatDate: function(dateObject) {
        const year = dateObject.getFullYear().toString();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + " - " + month + " - " + year.slice(2, 4);
        return formattetString;
      },
      selectDate: function(date) {
        if (state.startVacation.value === null) {
          state.startVacation.value = new Date(date);
          state.endVacation.value = new Date(date);
        } else if (state.startVacation.value > date) {
          state.startVacation.value = new Date(date);
          state.endVacation.value = new Date(date);
        } else if (
          state.endVacation.value.getTime() ===
          state.startVacation.value.getTime()
        ) {
          state.endVacation.value = new Date(date);
        } else {
          state.startVacation.value = new Date(date);
          state.endVacation.value = new Date(date);
        }
      },
      isStart: function(date) {
        if (state.startVacation.value !== null) {
          return state.startVacation.value.toISOString() === date.toISOString();
        } else {
          return false;
        }
      },
      isEnd: function(date) {
        if (
          state.endVacation.value === null &&
          state.startVacation.value !== null
        ) {
          return state.startVacation.value.toISOString() === date.toISOString();
        } else if (state.endVacation.value !== null) {
          return state.endVacation.value.toISOString() === date.toISOString();
        } else {
          return false;
        }
      },
      isSelected: function(date) {
        if (state.endVacation.value !== null) {
          return (
            state.startVacation.value <= date && state.endVacation.value >= date
          );
        } else {
          return false;
        }
      },
      decreaseMonth: function() {
        switch (state.month.value) {
          case 0:
            state.month.value = 11;
            state.year.value -= 1;
            break;
          default:
            state.month.value -= 1;
        }
        getDates();
      },
      increaseMonth: function() {
        switch (state.month.value) {
          case 11:
            state.month.value = 0;
            state.year.value += 1;
            break;
          default:
            state.month.value += 1;
        }
        getDates();
      },
      decreaseYear: function() {
        switch (state.year.value) {
          default:
            state.year.value -= 1;
        }
        getDates();
      },
      increaseYear: function() {
        switch (state.year.value) {
          default:
            state.year.value += 1;
        }
        getDates();
      }
    };

    return {
      t,
      ...state,
      ...functions
    };
  }
};
</script>

<style lang="scss" scoped>
.calendar {
  --side-padding: 20px; // padding to be applied to the side of the calendar
  --accent-br: 15px; // border-radius for accents under dates on hover

  &__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    & > div {
      text-align: center;
      font: {
        weight: 700;
        size: 1.02rem;
      }
    }
  }

  &__dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &__date {
    --height: calc(180px / 6);

    color: rgba(0, 144, 144, var(--tw-text-opacity));
    text-align: center;
    height: var(--height);
    line-height: var(--height);
    font: {
      weight: 600;
      size: 1.02rem;
    }
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-color: rgba(255, 255, 255, 0);
      width: 100%;
      height: calc(var(--height) * 0.9);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: var(--accent-br);
      transition: background-color 0.3s ease;
    }

    &:not(.calendar__date--selected):not(.calendar__date--grey):hover::before {
      background-color: #ffefdc;
      color: #f5ae00;
    }
    &:not(.calendar__date--selected):not(.calendar__date--grey):hover {
      color: #f5ae00;
    }

    &--grey {
      color: rgba(6, 90, 90, var(--tw-text-opacity));
      cursor: not-allowed;
    }

    &--selected {
      color: #f5ae00;

      &::before {
        background-color: #ffefdc;
        border-radius: 0px;
      }
    }

    &--first-date {
      &::before {
        border-top-left-radius: var(--accent-br);
        border-bottom-left-radius: var(--accent-br);
      }
    }

    &--last-date {
      &::before {
        border-top-right-radius: var(--accent-br);
        border-bottom-right-radius: var(--accent-br);
      }
    }

    &--range-start {
      &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        border-radius: 24px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f5ae00;
        width: 10px;
        height: 4px;
      }
    }

    &--range-end {
      color: #fff;

      &::before {
        box-shadow: 0 15px 20px -3px rgba(245, 174, 0, 0.35);
        background-color: #f5ae00;
        border-radius: var(--accent-br);
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        height: calc(var(--height) * 0.9);
        background-color: #ffefdc;
        width: 50px;
        top: 50%;
        right: 50%;
        transform: translateY(-50%);
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }
}

select,
button {
  border-radius: 20px;
  outline: none;
  border: 0;
  padding: 15px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  background: {
    image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='960px' height='560px' viewBox='0 0 960 560' enable-background='new 0 0 960 560' xml:space='preserve'%3E%3Cg id='Rounded_Rectangle_33_copy_4_1_'%3E%3Cpath d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z'/%3E%3C/g%3E%3C/svg%3E");
    size: 24px;
    repeat: no-repeat;
    position: calc(100% - var(--side-padding)) center;
  }
}
</style>
