<template
  ><div
    class="relative my-3 shadow-md bg-white border-green border-l-8 text-green-dark px-3 pb-3 pt-6 flex flex-col justify-evenly items-center"
  >
    <p v-if="data.accepted" class="text-gray text-sm absolute left-2 top-1">
      <span class=" w-8 h-8 flex justify-center items-center text-sm">
        <i class="fas fa-check-square"></i> </span
      >{{ t("approved") }}
    </p>
    <p v-if="data.declined" class="flex flex-col md:flex-row items-center">
      <span class=" w-8 h-8 flex justify-center items-center text-sm">
        <i class="fas fa-ban"></i> </span
      >{{ t("declined") }}
    </p>
    <p v-if="data.deleted" class="text-gray text-sm absolute left-2 top-1">
      <span class=" w-8 h-8 flex justify-center items-center">
        <i class="fas fa-trash-alt"></i> </span
      >{{ t("deleted") }}
    </p>
    <div
      v-if="!(data.deleted || data.accepted || data.declined)"
      class="text-gray text-sm absolute left-2 top-2 flex"
    >
      <div class="lds-roller -mt-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p v-if="!isMobile()" class="md:ml-3 text-xs mt-1">{{ t("waiting") }}</p>
    </div>
    <div class="text-gray text-sm absolute left-1/2 top-2 flex">
      <p v-if="data.data.vacation" class="md:ml-3 text-xs mt-1">
        {{ t("vacation") }}
      </p>
      <p v-if="data.data.sick" class="md:ml-3 text-xs mt-1">{{ t("sick") }}</p>
    </div>
    <p class="text-gray text-sm absolute right-0 top-1 flex">
      <span
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            data.requestFor === employeeDoc.id
        "
        class="transition duration-300 ease-in-out hover:text-green-lightest text-green-light flex-shrink-0 cursor-pointer text-2xl mr-3 flex justify-center items-center z-10"
        :title="t('acceptRequest')"
        @click.stop="showAcceptModal = !showAcceptModal"
      >
        <i class="fas fa-check-square"></i>
      </span>
      <span
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            data.requestFor === employeeDoc.id
        "
        class="transition duration-300 ease-in-out hover:text-green text-red-500 flex-shrink-0 cursor-pointer text-2xl mr-3 flex justify-center items-center z-10"
        :title="t('declineRequest')"
        @click.stop="showDeclineModal = !showDeclineModal"
      >
        <i class="fas fa-ban"></i>
      </span>
      <span
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            (isOwner() || isLeader())
        "
        class="transition duration-300 ease-in-out hover:text-green text-red-500 flex-shrink-0 cursor-pointer text-2xl mr-3 flex justify-center items-center z-10"
        :title="t('deleteRequest')"
        @click.stop="submitDeleteRequest(request.id)"
      >
        <i class="fas fa-trash-alt"></i>
      </span>
    </p>
    <p
      v-show="!isEmployee() || request.data().data.vacation"
      class="text-gray text-sm mt-3"
    >
      {{ request.data().data.vacation ? t("holidaysSpent") : t("sickDays") }}
      <span
        class="ml-3 -mt-4 -mr-4 w-6 h-6 rounded-full bg-green-dark text-white text-lg inline-flex items-center justify-center"
        >{{ request.data().data.vacationDays }}</span
      >
    </p>
    <p class=" text-green mt-3">
      {{ formatDate(request.data().data.start.toDate()) }}
      <span class="text-gray mx-3">{{ t("to") }}</span>
      {{ formatDate(request.data().data.end.toDate()) }}
    </p>
    <span class="text-sm flex items-center justify-evenly w-full">
      <p>{{ t("from") }} {{ formatIdToName(data.requestBy) }}</p>
      <p v-if="!isEmployee()">
        {{ t("to2") }} {{ formatIdToName(data.requestFor) }}
      </p></span
    >
    <span class="text-sm flex items-center justify-evenly">
      <p v-if="data.accepted">
        {{ t("approvedBy") }} {{ formatIdToName(data.accepted.by) }}
      </p>
      <p v-if="data.declined">
        {{ t("declinedBy") }} {{ formatIdToName(data.declined.by) }}
      </p></span
    >
  </div>
  <Modal
    @closeModal="showAcceptModal = !showAcceptModal"
    :visible="showAcceptModal"
  >
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-umbrella-beach"></i>
    </span>

    <p>
      Er du sikker på du vil
      <span class="text-green-light font-bold">godkende</span> denne
      ferie-/fridag?
    </p>
    <div class="flex mt-3">
      <Button
        class="bg-green-light hover:bg-green-lightest mr-2"
        label="Godkend"
        @click="submitAcceptRequest(request)"
      />
      <Button
        class="bg-red-500 hover:bg-red-400 ml-2"
        label="Anuller"
        @click="showAcceptModal = false"
      />
    </div>
  </Modal>
  <Modal
    @closeModal="showDeclineModal = !showDeclineModal"
    :visible="showDeclineModal"
  >
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-umbrella-beach"></i>
    </span>

    <p>
      Er du sikker på du vil
      <span class="text-red-500 font-bold">afvise</span> denne ferie-/fridag?
    </p>
    <div class="flex mt-3">
      <Button
        class="bg-green-light hover:bg-green-lightest mr-2"
        label="Godkend"
        @click="submitDeclineRequest(request)"
      />
      <Button
        class="bg-red-500 hover:bg-red-400 ml-2"
        label="Anuller"
        @click="showDeclineModal = false"
      />
    </div>
  </Modal>
</template>

<script>
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { ref } from "vue";
import { authentication } from "@/global/services/authentication";
import { employee } from "@/global/services/employee";
import { systemState } from "@/global/services/systemState";
import { errorLogging } from "@/global/services/errorLogging";
import { isMobile } from "@/global/services/mixins";
import { vacations } from "@/timeregistration/services/vacations";

import { useI18n } from "vue-i18n";

export default {
  name: "AddVacationItem",
  components: {
    Modal,
    Button
  },
  props: {
    request: Object
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          vacation: "Vacation",
          sick: "Sickleave",
          approved: "Approved",
          declined: "Afvist",
          deleted: "Deleted",
          waiting: "Awaiting approval",
          acceptRequest: "Accept Request",
          declineRequest: "Decline Request",
          deleteRequest: "Delete request",
          holidaysSpent: "Number of vacation days",
          sickDays: "Number of sick days",
          to: "to",
          from: "From:",
          to2: "To:",
          approvedBy: "Approved by:",
          declinedBy: "Declined by:"
        },
        dk: {
          vacation: "Ferie",
          sick: "Sygdom",
          approved: "Godkendt",
          declined: "Afvist",
          deleted: "Slettet",
          waiting: "Afventer godkendelse",
          acceptRequest: "Godkend anmodning",
          declineRequest: "Afvis anmodning",
          deleteRequest: "Slet anmodning",
          holidaysSpent: "Antal feriedage",
          sickDays: "Antal sygedage",
          to: "til",
          from: "Fra:",
          to2: "Til:",
          approvedBy: "Godkendt af:",
          declinedBy: "Afvist af:"
        }
      }
    });
    const state = {
      showAcceptModal: ref(false),
      showDeclineModal: ref(false),
      employeeDoc: employee.get(true),
      data: ref(props.request.data()),
      start: ref(
        props.request
          .data()
          .data.start.toDate()
          .getTime()
      ),
      end: ref(
        props.request
          .data()
          .data.end.toDate()
          .getTime()
      )
    };

    const functions = {
      isMobile,
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,
      formatIdToName: employee.formatIdToName,

      formatDate: function(dateObject) {
        const year = dateObject.getFullYear().toString();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + " - " + month + " - " + year.slice(2, 4);
        return formattetString;
      },
      formatRequestDate: function(dateObject) {
        const year = dateObject.getFullYear().toString();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + " - " + month + " - " + year.slice(2, 4);
        return formattetString;
      },

      submitDeleteRequest: function(requestId) {
        systemState.startProcessing();
        vacations.deleteRequest(requestId).then(message => {
          errorLogging.setSuccess(message);
          systemState.stopProcessing();
        });
      },

      submitAcceptRequest: function(request) {
        systemState.startProcessing();
        vacations
          .acceptAdd(request)
          .then(message => {
            systemState.stopProcessing();
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            systemState.stopProcessing();
            errorLogging.setError(message);
          });
      },

      submitDeclineRequest: function(request) {
        systemState.startProcessing();
        vacations
          .declineRequest(request)
          .then(message => {
            systemState.stopProcessing();
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            systemState.stopProcessing();
            errorLogging.setError(message);
          });
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  transform: scale(0.3);
}
.lds-roller div {
  animation: lds-roller 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f5ae00;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
