<template>
  <Button
    :label="t(`holidayVacations.addForSelf`)"
    class="mt-3 mr-3 ml-1 md:ml-0 md:mr-0 bg-yellow"
    @click="openModal()"
  />
  <Modal @closeModal="showModal = !showModal" :visible="showModal">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-umbrella-beach"></i>
    </span>

    <p>{{ t("message") }}</p>
    <span class="flex items-center">
      <DatePicker
        class="bg-white text-green-darkest"
        :disabled="true"
        v-model="startTime"
      />
      <p class="mx-4" v-if="endTime">{{ t("to") }}</p>
      <DatePicker
        class="bg-white text-green-darkest"
        v-if="endTime"
        :disabled="true"
        v-model="endTime"
      />
    </span>

    <Input
      class="py-6"
      inputWidth="w-20"
      :label="t('label')"
      type="number"
      v-model="vacationDays"
    />
    <Button
      class="bg-yellow hover:bg-green"
      :label="t('confirm')"
      @click="submitVacation()"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Input from "@/components/Input";
import DatePicker from "@/components/DatePicker";
import { errorLogging } from "@/global/services/errorLogging";
import { systemState } from "@/global/services/systemState";
import { ref, watchEffect } from "vue";
import { vacations } from "@/timeregistration/services/vacations";
import { employee } from "@/global/services/employee";

import { useI18n } from "vue-i18n";

export default {
  name: "TakeVacationButton",
  components: {
    Button,
    Modal,
    DatePicker,
    Input
  },
  props: {
    start: Date,
    end: Date
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          message: "Register a vacation/free day for",
          to: "to",
          label: "Vacation days",
          confirm: "Confirm"
        },
        dk: {
          message: "Registrer ferie-/fridag den",
          to: "til",
          label: "Antal feriedage",
          confirm: "Godkend"
        }
      }
    });
    const state = {
      showModal: ref(false),
      startTime: ref(props.start?.getTime()),
      endTime: ref(props.end?.getTime()),
      vacationDays: ref(0)
    };
    watchEffect(() => {
      state.startTime.value = props.start?.getTime();
      state.endTime.value = props.end?.getTime();
    });
    const functions = {
      openModal: () => {
        if (props.start !== null)
          state.showModal.value = !state.showModal.value;
        else errorLogging.setWarning("Vælg venligst en eller flere dage");
      },
      submitVacation: () => {
        systemState.startProcessing();
        const data = {
          start: props.start,
          end: props.end,
          vacationDays: state.vacationDays.value,
          eid: employee.getId()
        };
        vacations
          .addVacation(data)
          .then(() => {
            systemState.stopProcessing();
            state.showModal.value = false;
            errorLogging.setSuccess("Ferie-/fridag tilføjet");
          })
          .catch(message => {
            errorLogging.setWarning(
              "Ferie-/fridag blev ikke tilføjet: " + message
            );
            systemState.stopProcessing();
          });
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
