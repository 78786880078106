<template>
  <div
    class="relative my-3 shadow-md bg-white border-green border-l-8 text-green-dark p-3 flex flex-col justify-evenly items-center"
  >
    <p v-if="data.accepted" class="text-gray text-sm absolute left-2 top-1">
      <span class=" w-8 h-8 flex justify-center items-center text-sm">
        <i class="fas fa-check-square"></i> </span
      >{{ t("approved") }}
    </p>
    <p v-if="data.declined" class="flex flex-col md:flex-row items-center">
      <span class=" w-8 h-8 flex justify-center items-center text-sm">
        <i class="fas fa-ban"></i> </span
      >{{ t("declined") }}
    </p>
    <p v-if="data.deleted" class="text-gray text-sm absolute left-2 top-1">
      <span class=" w-8 h-8 flex justify-center items-center">
        <i class="fas fa-trash-alt"></i> </span
      >{{ t("deleted") }}
    </p>
    <div
      v-if="!(data.deleted || data.accepted || data.declined)"
      class="text-gray text-sm absolute left-2 top-2 flex"
    >
      <div class="lds-roller -mt-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="ml-3 text-xs mt-1">{{ t("waiting") }}</p>
    </div>
    <p
      class="text-gray text-sm absolute right-0 top-1 flex"
      v-if="selectedUnit !== undefined"
    >
      <span
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            data.requestBy !== employeeDoc.id
        "
        class="transition duration-300 ease-in-out hover:text-green text-green-light flex-shrink-0 cursor-pointer text-xl mr-3 flex justify-center items-center z-10"
        :title="t('acceptRequest')"
        @click.stop="showModal = !showModal"
      >
        <i class="fas fa-check-square"></i>
      </span>
      <span
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            data.requestBy !== employeeDoc.id
        "
        class="transition duration-300 ease-in-out hover:text-green text-red-500 flex-shrink-0 cursor-pointer text-xl mr-3 flex justify-center items-center z-10"
        :title="t('declineRequest')"
        @click.stop="submitDeclineRequest(request.ref)"
      >
        <i class="fas fa-ban"></i>
      </span>
      <span
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            !(isOwner() || isLeader())
        "
        class="transition duration-300 ease-in-out hover:text-green text-red-500 flex-shrink-0 cursor-pointer text-xl mr-3 flex justify-center items-center z-10"
        :title="t('deleteRequest')"
        @click.stop="submitDeleteRequest(request.id)"
      >
        <i class="fas fa-trash-alt"></i>
      </span>
    </p>

    <span class="mt-6 flex items-center justify-center">
      <p
        v-if="
          !(data.deleted || data.accepted || data.declined) &&
            data.requestBy !== employeeDoc.id
        "
      >
        {{ t("from") }} {{ formatIdToName(request.data().requestBy) }}
      </p>
      <p v-if="data.accepted">
        {{ t("approvedBy") }} {{ formatIdToName(data.accepted.by) }}
      </p>
      <p v-if="data.declined">
        {{ t("declinedBy") }} {{ formatIdToName(data.declined.by) }}
      </p></span
    >
    <p class=" text-green ">
      {{ formatDate(request.data().data.start.toDate()) }}
      <span v-if="end !== start" class="text-gray mx-3">{{ t("to") }}</span>
      <span v-if="end !== start">{{
        formatDate(request.data().data.end.toDate())
      }}</span>
    </p>
    <p
      v-if="
        !(data.deleted || data.accepted || data.declined) &&
          data.requestBy !== employeeDoc.id &&
          !data.data.sick
      "
    >
      {{ t("message1") }}
      <span class="text-green-light font-bold" v-if="request.data().vacation">{{
        t("approves")
      }}</span
      ><span class="text-red-500 font-bold" v-if="!request.data().vacation">{{
        t("declines")
      }}</span>
      {{ t("message2") }}
    </p>
    <p
      v-if="
        !(data.deleted || data.accepted || data.declined) &&
          data.requestBy !== employeeDoc.id &&
          data.data.sick
      "
    >
      Medarbejderen har anmodet om sygedage.
    </p>
    <p
      v-if="
        !(data.deleted || data.accepted || data.declined) &&
          data.requestBy === employeeDoc.id &&
          !data.data.sick
      "
    >
      Du har
      <span class="text-green-light font-bold" v-if="request.data().vacation"
        >godkendt</span
      ><span class="text-red-500 font-bold" v-if="!request.data().vacation"
        >afvist</span
      >
      at perioden kan optages om ferie.
    </p>
    <p
      v-if="
        !(data.deleted || data.accepted || data.declined) &&
          data.requestBy === employeeDoc.id &&
          data.data.sick
      "
    >
      Du har anmodet om sygedage.
    </p>
  </div>
  <Modal @closeModal="showModal = !showModal" :visible="showModal">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-umbrella-beach"></i>
    </span>

    <p>
      Godkend <span v-if="request.data().vacation">feriedage</span
      ><span v-if="!request.data().vacation && !data.data.sick">fridage</span
      ><span v-if="data.data.sick">sygedage</span> den
    </p>
    <span class="flex items-center">
      <DatePicker
        class="bg-white text-green-darkest"
        :disabled="true"
        v-model="start"
      />
      <p class="mx-4" v-if="end !== start">til</p>
      <DatePicker
        class="bg-white text-green-darkest"
        v-if="end !== start"
        :disabled="true"
        v-model="end"
      />
    </span>
    <p v-if="!request.data().data.sick" class="mt-3">
      Den ansatte
      <span class="text-green-light font-bold" v-if="request.data().vacation"
        >godkender</span
      ><span class="text-red-500 font-bold" v-if="!request.data().vacation"
        >afviser</span
      >
      at perioden kan optages om ferie.
    </p>
    <p v-if="request.data().data.sick" class="mt-3">
      Den ansatte anmoder om en sygedage.
    </p>
    <Input
      v-if="request.data().vacation"
      class="mt-3"
      inputWidth="w-20"
      label="Antal feriedage"
      type="number"
      :error="error"
      :errorMessage="errorMessage"
      v-model="vacationDays"
    />
    <Input
      v-if="request.data().data.sick"
      class="mt-3"
      inputWidth="w-20"
      label="Antal sygedage"
      type="number"
      :error="error"
      :errorMessage="errorMessage"
      v-model="vacationDays"
    />
    <Button
      class="bg-yellow hover:bg-green mt-3"
      label="Godkend"
      @click="submitAcceptRequest(request, vacationDays)"
    />
  </Modal>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { ref } from "vue";
import { units } from "@/global/services/units";
import { authentication } from "@/global/services/authentication";
import { employee } from "@/global/services/employee";
import { systemState } from "@/global/services/systemState";
import { errorLogging } from "@/global/services/errorLogging";
import { isMobile } from "@/global/services/mixins";
import { requests } from "@/global/services/requests";

import { useI18n } from "vue-i18n";

export default {
  name: "RequestVacationItem",
  components: {
    DatePicker,
    Modal,
    Input,
    Button
  },
  props: {
    request: Object
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          approved: "Approved",
          declined: "Afvist",
          deleted: "Deleted",
          waiting: "Awaiting approval",
          acceptRequest: "Accept Request",
          declineRequest: "Decline Request",
          deleteRequest: "Delete request",
          holidaysSpent: "Holidays spent",
          to: "to",
          from: "From:",
          to2: "To:",
          approvedBy: "Approved by:",
          declinedBy: "Declined by:",
          message1: "The employee",
          message2: "the request can be taken as a holiday.",
          approves: "approves",
          declines: "declines"
        },
        dk: {
          approved: "Godkendt",
          declined: "Afvist",
          deleted: "Slettet",
          waiting: "Afventer godkendelse",
          acceptRequest: "Godkend anmodning",
          declineRequest: "Afvis anmodning",
          deleteRequest: "Slet anmodning",
          holidaysSpent: "Forbrugte feriedage",
          to: "til",
          from: "Fra:",
          to2: "Til:",
          approvedBy: "Godkendt af:",
          declinedBy: "Afvist af:",
          message1: "Medarbejderen",
          message2: "at anmodningen kan optages som ferie.",
          approves: "godkender",
          declines: "afviser"
        }
      }
    });
    const state = {
      error: ref(false),
      errorMessage: ref(""),
      showModal: ref(false),
      vacationDays: ref(0),
      employeeDoc: employee.get(true),
      selectedUnit: ref(units.getSelectedUnit(true)),
      data: ref(props.request.data()),
      start: ref(
        props.request
          .data()
          .data.start.toDate()
          .getTime()
      ),
      end: ref(
        props.request
          .data()
          .data.end.toDate()
          .getTime()
      )
    };
    const functions = {
      isMobile,
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,
      formatIdToName: employee.formatIdToName,

      formatDate: function(dateObject) {
        const year = dateObject.getFullYear().toString();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + " - " + month + " - " + year.slice(2, 4);
        return formattetString;
      },
      formatRequestDate: function(dateObject) {
        const year = dateObject.getFullYear().toString();
        let month = (dateObject.getMonth() + 1).toString();
        let date = dateObject.getDate().toString();
        month.length === 1 ? (month = "0" + month) : month;
        date.length === 1 ? (date = "0" + date) : date;
        const formattetString = date + " - " + month + " - " + year.slice(2, 4);
        return formattetString;
      },

      submitDeleteRequest: function(requestId) {
        systemState.startProcessing();
        requests.deleteRequest(requestId).then(message => {
          errorLogging.setSuccess(message);
          systemState.stopProcessing();
        });
      },

      submitAcceptRequest: function(requestDocument) {
        const checkDate = requestDocument.data().data.start.toDate();
        checkDate.setDate(
          checkDate.getDate() + parseInt(state.vacationDays.value)
        );
        console.log(checkDate);
        console.log(requestDocument.data().data.end.toDate());
        if (checkDate > requestDocument.data().data.end.toDate()) {
          state.error.value = true;
          state.errorMessage.value =
            "Antal feriedage kan ikke værre større end antallet af dage i perioden";
          return;
        } else if (state.vacationDays.value < 0) {
          state.error.value = true;
          state.errorMessage.value = "Antal feriedage kan ikke være negativt";
          return;
        }
        state.error.value = false;
        state.errorMessage.value = "";
        systemState.startProcessing();
        requests
          .acceptVacationRequest(requestDocument, state.vacationDays.value)
          .then(message => {
            systemState.stopProcessing();
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            systemState.stopProcessing();
            errorLogging.setError(message);
          });
      },

      submitDeclineRequest: function(requestId) {
        systemState.startProcessing();
        requests
          .declineRequest(requestId)
          .then(message => {
            systemState.stopProcessing();
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            systemState.stopProcessing();
            errorLogging.setError(message);
          });
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  transform: scale(0.3);
}
.lds-roller div {
  animation: lds-roller 2.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f5ae00;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
