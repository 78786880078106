<template>
  <Button
    :label="t(`holidayVacations.request`)"
    class="mt-3 bg-yellow w-48"
    @click="openModal()"
  />
  <Modal @closeModal="showModal = !showModal" :visible="showModal">
    <span
      class="flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-6 text-white"
    >
      <i class="fas fa-umbrella-beach"></i>
    </span>

    <p>{{ t("message") }}</p>

    <span class="flex items-center mt-3">
      <DatePicker
        class="bg-white text-green-darkest"
        :disabled="true"
        v-model="startTime"
      />
      <p class="mx-4" v-if="endTime !== startTime">{{ t("to") }}</p>
      <DatePicker
        class="bg-white text-green-darkest"
        v-if="endTime !== startTime"
        :disabled="true"
        v-model="endTime"
      />
    </span>
    <Checkbox
      :label="t('label')"
      class="my-3"
      :small="true"
      v-model="sick"
      @change="sick"
    />
    <p v-if="!sick" class="mt-3">
      {{ t("message2") }}
      <span class="text-green-light font-bold">{{ vacationDaysLeft }}</span>
      {{ t("message3") }}
    </p>
    <Checkbox
      v-if="!sick"
      label="Må perioden registreres som ferie?"
      class="my-3"
      :small="true"
      v-model="vacation"
      @change="vacation"
    />
    <Button
      class="bg-yellow hover:bg-green"
      :label="t('confirm')"
      @click="submitNewRequest()"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import DatePicker from "@/components/DatePicker";
import Checkbox from "@/components/Checkbox";
import { errorLogging } from "@/global/services/errorLogging";
import { requests } from "@/global/services/requests";
import { systemState } from "@/global/services/systemState";
import { ref, watchEffect } from "vue";
import { units } from "@/global/services/units";
import { employee } from "@/global/services/employee";

import { useI18n } from "vue-i18n";

export default {
  name: "RequestVacationButton",
  components: {
    Button,
    Modal,
    DatePicker,
    Checkbox
  },
  props: {
    start: Date,
    end: Date,
    vacationDaysLeft: Number
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          message: "Request a vacation/free day for",
          to: "to",
          label: "Should the period be registered as sickleave?",
          message2: "You have",
          message3: "holidays left.",
          confirm: "Confirm"
        },
        dk: {
          message: "Anmod om en ferie-/fridag den",
          to: "til",
          label: "Skal perioden registreres som sygedage?",
          message2: "Du har",
          message3: "feriedage tilbage.",
          confirm: "Godkend"
        }
      }
    });
    const state = {
      showModal: ref(false),
      startTime: ref(props.start?.getTime()),
      endTime: ref(props.end?.getTime()),
      vacationDays: ref(0),
      sick: ref(false),
      vacation: ref(false)
    };
    watchEffect(() => {
      state.startTime.value = props.start?.getTime();
      state.endTime.value = props.end?.getTime();
    });
    const functions = {
      openModal: () => {
        if (props.start !== null)
          state.showModal.value = !state.showModal.value;
        else errorLogging.setWarning("Vælg venligst en eller flere dage");
      },
      submitNewRequest: () => {
        systemState.startProcessing();
        const data = {
          data: {
            start: props.start,
            end: props.end,
            vacationDays: state.vacationDays.value,
            sick: state.sick.value,
            eid: employee.getId()
          },
          vacation: state.vacation.value,
          requestFor: units.getSelectedUnitId(),
          requestBy: employee.getId(),
          requestType: "RequestVacationDay"
        };
        requests
          .createVacationRequest(data)
          .then(message => {
            systemState.stopProcessing();
            state.showModal.value = false;
            errorLogging.setSuccess(message);
          })
          .catch(message => {
            errorLogging.setError(message);
            systemState.stopProcessing();
          });
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
